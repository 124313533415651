<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9" class="manage-endorsements-wrapper">
            <!-- Info Block -->
            <b-card class="mb-4">
              <b-card-body class="p-0">
                <b-card-title>
                  Manage your election book.
                </b-card-title>
                <div v-if="isDeadlineNotOver">
                  <div class="guide-tip">
                    <b-row align-v="center">
                      <b-col cols="3" lg="1" class="icon">
                        <font-awesome-icon :icon="['fas', 'users']" />
                      </b-col>
                      <b-col
                        cols="12"
                        lg="8"
                        order="3"
                        order-lg="2"
                        class="text"
                      >
                        <div>
                          Now that you have endorsements you can choose to show
                          them on your site. You can toggle which endorsements
                          you would to feature first and you can update the
                          visibility of each endorsement individually.
                        </div>
                        <div class="mt-2" v-if="candidate.isPetition">
                          As a petition candidate, you can track the number of
                          signatures you received on the next step in this
                          wizard.
                        </div>
                      </b-col>
                      <b-col
                        cols="9"
                        lg="3"
                        order="2"
                        order-lg="3"
                        class="button"
                      >
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          v-b-modal.modal-theme-guide
                        >
                          View Example
                          <font-awesome-icon
                            :icon="['far', 'window-maximize']"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="mt-5 mb-3">
                    <b-row>
                      <b-col lg="3">
                        <img
                          src="../../assets/images/admin/endorsements/booklet.svg"
                          class="img-fluid"
                        />
                      </b-col>
                      <b-col lg="9" class="align-self-center">
                        <h5 class="font-weight-normal">
                          Your Non-Candidate Statement is ready to download.
                        </h5>
                        <p class="text-muted">
                          You can edit your Non-Candidate Statement in the
                          previous step.
                        </p>
                        <b-button
                          variant="primary"
                          size="lg"
                          @click="onDownloadPdf"
                          :disabled="downloading"
                        >
                          <b-spinner
                            small
                            style="position: relative; top: -3px"
                            v-if="downloading"
                          ></b-spinner>
                          Download Pdf
                          <font-awesome-icon
                            :icon="['fas', 'file-pdf']"
                            class="pl-1"
                          />
                        </b-button>
                        <p class="mt-2">
                          <small class="text-muted"
                            >* The PDF will only show what has been published to
                            your website.</small
                          >
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="mt-5 auto-send">
                          <b-form-checkbox
                            class="ml-4"
                            @change="onAutoSendChange"
                            v-model="
                              candidateModel.autoSendNonCandidateStatementsToAdmin
                            "
                            :value="Boolean(true)"
                            :unchecked-value="Boolean(false)"
                          >
                            <label
                              >By checking this box, we will automatically send
                              your non-candidate statements PDF to admin after
                              the deadline of submission.</label
                            >
                          </b-form-checkbox>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div v-else>
                  <p>
                    Deadline of endorsement submission is over. Your
                    non-candidate statement booklet has been sent to the admin.
                  </p>
                </div>
              </b-card-body>
            </b-card>
            <AdminFooterButton
              @on-back="onBack"
              :hide-save-button="Boolean(true)"
              :hide-skip-button="Boolean(true)"
            />
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="endorsementList"
      :template-id="candidateModel.profileTheme"
    />
    <ModalEndorsementExample />
  </div>
</template>
<script>
import Repository from "@/repositories/RepositoryFactory";
const ExportRepository = Repository.get("ExportRepository");
import CandidateMixin from "@/mixins/candidate.mixin";
import ModalEndorsementExample from "@/components/admin/ModalEndorsementExample.vue";
export default {
  name: "ElectionBook",
  components: { ModalEndorsementExample },
  mixins: [CandidateMixin],
  async mounted() {
    await this.$store.dispatch(
      "candidates/getCandidate",
      this.$route.params.id
    );

    const intervalTime = 10000;

    this.intervalId = setInterval(() => {
      this.isDeadlineNotOver =
        new Date() < new Date(this.settings.lastDateOfEndorsementSubmission);
    }, intervalTime);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  data() {
    return {
      downloading: false,
      isDeadlineNotOver: true,
      intervalId: null
    };
  },
  methods: {
    onBack() {
      this.$router.push("manage-endorsements");
    },
    async onDownloadPdf() {
      try {
        this.downloading = true;
        setTimeout(async () => {
          await ExportRepository.DownloadNonCandidateStatements(
            this.candidateModel.userId,
            this.candidateModel.slug
          );
          this.downloading = false;
        }, 500);
      } catch (e) {
        console.log(e);
      }
    },
    async onAutoSendChange() {
      await this.$store.dispatch("candidates/saveAutoSendCandidateStatements", {
        autoSend: this.candidateModel.autoSendNonCandidateStatementsToAdmin,
        userId: this.candidateModel.userId
      });
    }
  },
  computed: {
    candidate() {
      return this.candidateModel;
    },
    allEndorsements() {
      return this.candidateModel.endorsements;
    },
    settings() {
      return this.$store.getters["settings/current"];
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/admin/_base.scss";

.auto-send {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #80808017;
  border-radius: 5px;
  color: #333333;
}

.manage-endorsements-wrapper {
  @media (max-width: $lg-width) {
    img {
      width: 60%;
      margin-bottom: 40px;
    }
  }
}
</style>
