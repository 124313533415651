<template>
  <b-modal id="modal-endorsement-example"
           title="Endorsement Display Examples"
           size="lg"
           scrollable
           hide-footer
  >
    <div>
      <b-tabs content-class="mt-4 mb-5">
        <b-tab title="Default" active>
          <div class="alert alert-primary">If you do not feature any endorsements, your endorsement list will be displayed in alphabetical order from A-Z. It will appear like the example below.</div>
          <img src="../../assets/images/admin/endorsements/layout-default.png" class="img-fluid" />
        </b-tab>
        <b-tab title="Featured">
          <div class="alert alert-primary">Featuring an endorsement will allow you to customize the order and group names together with a header title. Your featured endorsements will always be displayed first before your full list of endorsements. You can choose between <span class="font-weight-bold">Left Aligned</span> or <span class="font-weight-bold">Center Aligned</span>.</div>
          <h4>Left Aligned</h4>
          <img src="../../assets/images/admin/endorsements/layout-left.png" class="img-fluid" />
          <h4 class="mt-5">Center Aligned</h4>
          <img src="../../assets/images/admin/endorsements/layout-center.png" class="img-fluid" />
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>
export default {

};
</script>

<style lang="scss">

  #modal-endorsement-example {
    .alert {
        margin-bottom: 30px;
    }

    h4 {
        font-weight: 900;
        margin-bottom: 30px;
        text-align: center;        
    }

    img {
      border: 2px solid #333;
      width: 80%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .nav-tabs {
      font-weight: 600;

      .nav-item {
        background-color: #f5f5f5;
        border: 1px solid #ffffff;
      }
    }
  }
</style>
